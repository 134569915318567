<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Grupy opcji</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Grupy opcji</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Grupy opcji</h5>
          </div>
          <div class="ibox-content">
            <router-link to="/product-option-group/create">
              <button class="btn btn-sm btn-primary">Dodaj</button>
            </router-link>
            <div class="row">
              <div class="col-sm-12 m-b-xs">
                <div class="form-group">
                  <label class="control-label col-sm-2">
                    Wpisz liczbę wierszy:
                  </label>
                  <div class="col-sm-2">
                    <input
                      class="form-control inputClass"
                      type="text"
                      v-model="rowsPerPage"
                      @click="isSearch = false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 m-b-xs" v-if="items">
                <button @click="resetFilters" class="btn btn-primary btn-xs">
                  <i class="fa fa-refresh"></i>
                </button>
                <EasyDataTable
                  v-model:server-options="serverOptions"
                  :server-items-length="itemsAll"
                  :headers="headers"
                  :items="items"
                  :clickRowToExpand="true"
                  :loading="loading"
                  @click-row="editPOG"
                  alternating
                  show-index
                  multi-sort
                  border-cell
                  @update-sort="updateSort"
                  class="table-search"
                >
                  <template #header="header">
                    <span v-if="header.text">{{ header.text }}</span>
                    <input
                      class="table-search"
                      type="text"
                      v-if="header.search"
                      v-model="searchValue[header.value]"
                      @keyup="search"
                      @click="isSearch = true"
                    />
                  </template>
                  <template #expand>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="ibox float-e-margins">
                          <div class="ibox-title">
                            <h5>Edycja Grupy</h5>
                          </div>

                          <div class="form-horizontal">
                            <div class="ibox-content">
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Nazwa pl
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="pOptionsGroupObj.name_pl"
                                    name="name_pl"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Nazwa pl"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.name_pl"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.name_pl[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Nazwa en
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="pOptionsGroupObj.name_en"
                                    name="name_en"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Nazwa en"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.name_en"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.name_en[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Nazwa de
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="pOptionsGroupObj.name_de"
                                    name="name_de"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Nazwa de"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.name_de"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.name_de[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Nazwa de_de
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="pOptionsGroupObj.name_cpde"
                                    name="name_cpde"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Nazwa de_de"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.name_cpde"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.name_cpde[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Nazwa de_en
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="pOptionsGroupObj.name_cpen"
                                    name="name_cpen"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Nazwa de_en"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.name_cpen"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.name_cpen[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Opis
                                </label>
                                <div class="col-sm-10 QuillEditor-box">
                                  <QuillEditor
                                    theme="snow"
                                    contentType="html"
                                    v-model:content="
                                      pOptionsGroupObj.description
                                    "
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.description"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.description[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Wybór
                                </label>
                                <div class="col-sm-10">
                                  <Multiselect
                                    v-model="pOptionsGroupObj.group_choice"
                                    mode="single"
                                    placeholder="Wybór"
                                    track-by="name"
                                    label="name"
                                    :close-on-select="true"
                                    :search="true"
                                    :options="POGChoices"
                                  >
                                  </Multiselect>
                                  <div
                                    v-if="errorsAdminData?.data?.group_choice"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.group_choice[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <div class="col-sm-offset-2 col-sm-10">
                                  <div class="checkbox checkbox-primary">
                                    <label>
                                      <input
                                        v-model="
                                          pOptionsGroupObj.determines_paper_type
                                        "
                                        name="determines_paper_type"
                                        type="checkbox"
                                      />
                                      <span>Określa rodzaj papieru</span>
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <div class="col-sm-offset-2 col-sm-10">
                                  <div class="checkbox checkbox-primary">
                                    <label>
                                      <input
                                        v-model="
                                          pOptionsGroupObj.select_from_list
                                        "
                                        name="select_from_list"
                                        type="checkbox"
                                      />
                                      <span>Wybór z listy</span>
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <div class="col-sm-offset-2 col-sm-10">
                                  <div class="checkbox checkbox-primary">
                                    <label>
                                      <input
                                        v-model="pOptionsGroupObj.hide_in_order"
                                        name="hide_in_order"
                                        type="checkbox"
                                      />
                                      <span>
                                        Ukryj na karcie zamówienia i karcie
                                        montażu
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div class="hr-line-dashed"></div>

                              <div class="form-group">
                                <div class="col-sm-4 col-sm-offset-2">
                                  <button @click="cancel" class="btn btn-white">
                                    Anuluj
                                  </button>
                                  <button
                                    @click="saveEdit"
                                    class="btn btn-primary"
                                  >
                                    Zapisz
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #item-actions="item">
                    <button
                      @click.stop="
                        showModal = item.pk;
                        deleteName = item.name;
                      "
                      class="btn btn-danger btn-xs"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    v-if="showModal"
    :title="'Potwierdzenie usunięcia'"
    :content="'Czy na pewno chcesz usunąć <b>' + deleteName + '</b> ?'"
    @callbackOk="deletePOG(showModal)"
    @callbackClose="showModal = false"
  />
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import Modal from "../caffeprint/Modal.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
  name: "ProductOptionList",
  mixins: [],
  components: {
    Modal,
    Multiselect,
  },
  props: {},

  setup() {
    const store = useStore();
    const deletepOptionsGroupData = computed(
      () => store.getters.getDeletepOptionsGroupData
    );
    const pogData = computed(() => store.getters.getPOGData);
    const updatepOptionsGroupData = computed(
      () => store.getters.getUpdatepOptionsGroupData
    );
    const pOptionsGroupData = computed(
      () => store.getters.getpOptionsGroupData
    );
    const POGChoicesData = computed(() => store.getters.getPOGChoicesData);
    const pOptionsGroupObj = ref({
      select_from_list: false,
      determines_paper_type: false,
      hide_in_order: false,
    });
    let headers = [
      { text: "ID", value: "pk", sortable: true, search: true },
      { text: "Nazwa", value: "name", sortable: true, search: true },
      { text: "Opis", value: "description", sortable: true, search: true },
      { text: "Wybór", value: "group_choice", sortable: true, search: true },
      { text: "Akcje", value: "actions" },
    ];

    let items = ref([]);
    let itemsAll = ref(0);
    const POGChoices = ref([]);
    const showModal = ref(false);
    const deleteName = ref("");
    const loading = ref(true);
    const searchValue = ref({});
    const isSearch = ref(false);
    const rowsPerPage = ref();
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 25,
    });

    const getpOptionsGroup = async () => {
      loading.value = true;
      let sort;
      if (serverOptions.value.sortType === "desc") {
        sort = "-" + serverOptions.value.sortBy;
      } else {
        sort = serverOptions.value.sortBy;
      }
      await store.dispatch("getpOptionsGroup", {
        page: serverOptions.value.page,
        itemsPerPage: serverOptions.value.rowsPerPage,
        ordering: sort,
        search: searchValue.value,
      });
    };

    const deletePOG = (pk) => {
      showModal.value = false;
      store.dispatch("deletepOptionsGroup", {
        pk: pk,
      });
    };
    const saveEdit = () => {
      store.dispatch("updatepOptionsGroup", pOptionsGroupObj.value);
    };

    const cancel = () => {
      document.querySelector(".expanding")?.click();
    };

    const editPOG = (item) => {
      cancel();
      store.dispatch("getPOG", { pk: item.pk });
    };

    const search = () => {
      isSearch.value = true;
      if (searchValue.value) {
        setTimeout(() => {
          getpOptionsGroup();
        }, 1000);
      }
    };

    const resetFilters = () => {
      searchValue.value = {};
      serverOptions.value.sortBy = "";
      getpOptionsGroup();
    };

    const updateSort = () => {
      search.value = true;
    };
    store.dispatch("getPOGChoices");
    getpOptionsGroup();

    watch(
      () => rowsPerPage.value,
      function (newValue) {
        if (newValue) {
          serverOptions.value.rowsPerPage = newValue;
          setTimeout(() => {
            getpOptionsGroup();
          }, 1000);
        }
      }
    );

    watch(
      () => pOptionsGroupData.value,
      function (newValue) {
        itemsAll.value = newValue.data.count;
        items.value = [];
        newValue.data.results?.forEach((element) => {
          items.value.push(element);
        });
        loading.value = false;
      }
    );

    watch(
      () => pogData.value,
      function (newValue) {
        pOptionsGroupObj.value = newValue.data;
      }
    );

    watch(
      () => deletepOptionsGroupData.value,
      function (newValue) {
        if (newValue.status === 204) {
          store.dispatch("showToaster", {
            type: "success",
            content: "Grupa opcji została usunięta",
          });
        }
        getpOptionsGroup();
      }
    );

    watch(
      () => updatepOptionsGroupData.value,
      function (newValue) {
        if (newValue.status === 200) {
          getpOptionsGroup();
          store.dispatch("showToaster", {
            type: "success",
            content: "Dane zostały zaktualizowane",
          });
        }

        cancel();
      }
    );

    watch(
      () => POGChoicesData.value,
      function (newValue) {
        newValue.data?.forEach((element) => {
          POGChoices.value.push({
            name: element[1],
            value: element[0],
          });
        });
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        if (!isSearch.value) {
          getpOptionsGroup();
          isSearch.value = false;
        }
      }
    );

    return {
      headers,
      items,
      itemsAll,
      loading,
      serverOptions,
      showModal,
      deletePOG,
      deleteName,
      pOptionsGroupObj,
      cancel,
      editPOG,
      saveEdit,
      searchValue,
      search,
      resetFilters,
      updateSort,
      isSearch,
      rowsPerPage,
      POGChoices,
    };
  },
  computed: {},
};
</script>
