<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Klienci</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <router-link to="/clients/list">Klienci</router-link>
        </li>
        <li class="active">
          <strong>Szczegóły</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <button class="btn btn-primary">
            <span class="fa fa-check"></span>
            <span>Aktywuj</span>
          </button>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Dane klienta</h5>
          </div>
          <div class="ibox-content panel">
            <div v-if="clientObj.user" class="panel-body">
              <dl class="dl-horizontal">
                <dt>Imię</dt>
                <dd>
                  {{
                    clientObj.user.first_name + " " + clientObj.user.last_name
                  }}
                </dd>
                <dt>Adres e-mail</dt>
                <dd>{{ clientObj.user.email }}</dd>
                <dt>Metoda płatności</dt>
                <dd>{{ clientObj.payment_method ?? "-" }}</dd>
                <dt>Sklep</dt>
                <dd>{{ clientObj.company_localization_display ?? "-" }}</dd>
              </dl>
              <div class="hr-line-dashed"></div>
              <dl class="dl-horizontal">
                <dt>Nazwa firmy</dt>
                <dd>{{ clientObj.company_name ?? "-" }}</dd>
                <dt>NIP</dt>
                <dd>{{ clientObj.tax_id ?? "-" }}</dd>
                <dt>Numer telefonu</dt>
                <dd>{{ clientObj.telephone ?? "-" }}</dd>
                <dt>Adres</dt>
                <dd>{{ clientObj.address ?? "-" }}</dd>
                <dt>Numer budynku</dt>
                <dd>{{ clientObj.building_number ?? "-" }}</dd>
                <dt>Numer mieszkania</dt>
                <dd>{{ clientObj.suite_number ?? "-" }}</dd>
                <dt>Miasto</dt>
                <dd>{{ clientObj.city ?? "-" }}</dd>
                <dt>Kod pocztowy</dt>
                <dd>{{ clientObj.zip_code ?? "-" }}</dd>
                <dt>Kraj</dt>
                <dd>{{ clientObj.country ?? "-" }}</dd>
              </dl>
              <div class="hr-line-dashed"></div>
              <dl class="dl-horizontal">
                <dt>Grupa rabatowa</dt>
                <dd>{{ clientObj.discount_group ?? "-" }}</dd>
                <dt>Główne konto</dt>
                <dd>{{ clientObj.main_account_name ?? "-" }}</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Dodatkowy adres dostawy</h5>
          </div>
          <div class="ibox-content panel">
            <div class="panel-body">
              <dl class="dl-horizontal">
                <dt>Nazwa firmy</dt>
                <dd>{{ clientObj.company_name_2 ?? "-" }}</dd>
                <dt>NIP</dt>
                <dd>{{ clientObj.tax_id_2 ?? "-" }}</dd>
                <dt>Numer telefonu</dt>
                <dd>{{ clientObj.telephone_2 ?? "-" }}</dd>
                <dt>Adres</dt>
                <dd>{{ clientObj.address_2 ?? "-" }}</dd>
                <dt>Numer budynku</dt>
                <dd>{{ clientObj.building_number_2 ?? "-" }}</dd>
                <dt>Numer mieszkania</dt>
                <dd>{{ clientObj.suite_number_2 ?? "-" }}</dd>
                <dt>Miasto</dt>
                <dd>{{ clientObj.city_2 ?? "-" }}</dd>
                <dt>Kod pocztowy</dt>
                <dd>{{ clientObj.zip_code_2 ?? "-" }}</dd>
                <dt>Kraj</dt>
                <dd>-</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Zamówienia</h5>
          </div>
          <div
            v-if="clientOrdersData?.data?.results.length > 0"
            class="ibox-content"
          >
            <EasyDataTable
              v-model:server-options="serverLogsOptions"
              :server-items-length="clientOrdersData.data.results.length"
              :headers="ordersHeaders"
              :items="clientOrdersData.data.results"
              :clickRowToExpand="false"
              @click-row="goToOrder"
              :loading="loading"
              alternating
              show-index
              multi-sort
              border-cell
            >
            </EasyDataTable>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Faktury</h5>
          </div>
          <div
            v-if="clientInvoicesData?.data?.results.length > 0"
            class="ibox-content"
          >
            <EasyDataTable
              v-model:server-options="serverLogsOptions"
              :server-items-length="clientInvoicesData.data.results.length"
              :headers="invoicesHeaders"
              :items="clientInvoicesData.data.results"
              :clickRowToExpand="false"
              :loading="loading"
              alternating
              show-index
              multi-sort
              border-cell
            >
            </EasyDataTable>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Sprawy</h5>
          </div>
          <div
            v-if="clientMessagesData?.data?.results.length > 0"
            class="ibox-content"
          >
            <EasyDataTable
              v-model:server-options="serverLogsOptions1"
              :server-items-length="clientMessagesData.data.results.length"
              :headers="messagesHeaders"
              :items="clientMessagesData.data.results"
              @click-row="goToMessage"
              :clickRowToExpand="false"
              :loading="loading"
              alternating
              show-index
              multi-sort
              border-cell
            >
            </EasyDataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import router from "../../router/router";

export default {
  name: "ClientsDetails",
  mixins: [],
  components: {},
  props: {},

  setup() {
    const errorsAdminData = ref();
    const showErrors = ref(false);
    const store = useStore();
    const clientOrdersData = computed(() => store.getters.getClientOrdersData);
    const clientInvoicesData = computed(
      () => store.getters.getClientInvoicesData
    );
    const clientMessagesData = computed(
      () => store.getters.getClientMessagesData
    );
    const clientData = computed(() => store.getters.getClientData);
    let items = ref([]);
    const loading = ref(true);
    const clientObj = ref({});
    let itemsAll = ref(0);
    const route = useRoute();
    const searchValue = ref({});
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 25,
    });
    const serverLogsOptions = ref({
      page: 1,
      rowsPerPage: 25,
    });
    const serverOptions1 = ref({
      page: 1,
      rowsPerPage: 25,
    });
    let messagesHeaders = [
      {
        text: "Nr sprawy",
        value: "pk",
        sortable: true,
        search: true,
      },
      {
        text: "E-mail",
        value: "client_email",
        sortable: true,
        search: true,
      },
      {
        text: "Klient",
        value: "client_name",
        sortable: true,
        search: true,
      },
      {
        text: "Rodzaj sprawy",
        value: "competency",
        sortable: true,
        search: true,
      },
      {
        text: "Osoba odpowiedzialna",
        value: "responsible_person_name",
        sortable: true,
        search: true,
      },
      {
        text: "Status",
        value: "status_display",
        sortable: true,
        search: true,
      },
    ];

    let ordersHeaders = [
      {
        text: "Nr zamówienia",
        value: "pk",
        sortable: true,
        search: true,
      },
      {
        text: "E-mail",
        value: "client_email",
        sortable: true,
        search: true,
      },
      {
        text: "Klient",
        value: "client_name",
        sortable: true,
        search: true,
      },
      {
        text: "Sklep",
        value: "company_localization_display",
        sortable: true,
      },
      {
        text: "Język",
        value: "lang_display",
        sortable: true,
        search: true,
      },
      {
        text: "Waluta",
        value: "currency",
        sortable: true,
        search: true,
      },
      {
        text: "Netto",
        value: "info.final_net_price",
        sortable: true,
        search: true,
      },
      {
        text: "Netto produkty",
        value: "info.products_net_price",
        sortable: true,
        search: true,
      },
      {
        text: "Netto wysyłka",
        value: "info.delivery_price",
        sortable: true,
        search: true,
      },
      {
        text: "Stawka VAT",
        value: "info.vat_percent",
        sortable: true,
        search: true,
      },
      {
        text: "Wartość VAT",
        value: "info.vat_price",
        sortable: true,
        search: true,
      },
      {
        text: "Liczba adresów",
        value: "adresses_count",
        sortable: true,
        search: true,
      },
      {
        text: "Liczba paczek",
        value: "info.delivery_packages",
        sortable: true,
        search: true,
      },
      {
        text: "Metoda płatności",
        value: "payment_method_display",
        sortable: true,
        search: true,
      },
      {
        text: "Status zamówienia",
        value: "status_display",
        sortable: true,
        search: true,
      },
      {
        text: "Termin",
        value: "realization_date",
        sortable: true,
        search: true,
      },
    ];

    let invoicesHeaders = [
      {
        text: "Nr faktury",
        value: "number",
        sortable: true,
        search: true,
      },
      {
        text: "Nr zamówienia",
        value: "order",
        sortable: true,
        search: true,
      },
      {
        text: "Nazwa firmy",
        value: "company_name",
        sortable: true,
        search: false,
      },
      {
        text: "Sklep",
        value: "company_localization_display",
        sortable: true,
      },
      {
        text: "Język",
        value: "lang_display",
        sortable: true,
        search: true,
      },
      {
        text: "Waluta",
        value: "currency",
        sortable: true,
        search: true,
      },
      {
        text: "Netto",
        value: "net_price",
        sortable: true,
        search: true,
      },
      {
        text: "VAT",
        value: "vat",
        sortable: true,
        search: true,
      },
      {
        text: "Brutto",
        value: "gross_price",
        sortable: false,
        search: false,
      },
      {
        text: "Rodzaj faktury",
        value: "invoice_type_display",
        sortable: false,
        search: false,
      },
      {
        text: "Data wystawienia",
        value: "issue_date",
        sortable: true,
        search: true,
      },
    ];

    const getClientOrders = () => {
      loading.value = true;
      let sort;
      if (serverOptions.value.sortType === "desc") {
        sort = "-" + serverOptions.value.sortBy;
      } else {
        sort = serverOptions.value.sortBy;
      }
      store.dispatch("getClientOrders", {
        client_pk: route.params?.pk,
        page: serverOptions.value.page,
        itemsPerPage: serverOptions.value.rowsPerPage,
        ordering: sort,
        search: searchValue.value,
      });
    };

    const getClientMessages = () => {
      loading.value = true;
      let sort;
      if (serverOptions1.value.sortType === "desc") {
        sort = "-" + serverOptions1.value.sortBy;
      } else {
        sort = serverOptions1.value.sortBy;
      }
      store.dispatch("getClientMessages", {
        client_pk: route.params?.pk,
        page: serverOptions1.value.page,
        itemsPerPage: serverOptions1.value.rowsPerPage,
        ordering: sort,
        search: searchValue.value,
      });
    };

    const getClientInvoices = () => {
      loading.value = true;
      let sort;
      if (serverOptions1.value.sortType === "desc") {
        sort = "-" + serverOptions1.value.sortBy;
      } else {
        sort = serverOptions1.value.sortBy;
      }
      store.dispatch("getClientInvoices", {
        client_pk: route.params?.pk,
        page: serverOptions1.value.page,
        itemsPerPage: serverOptions1.value.rowsPerPage,
        ordering: sort,
        search: searchValue.value,
      });
    };

    const goToMessage = (item) => {
      router.push({
        name: "MessagesDetails",
        path: "/messages/details/",
        params: { pk: item.pk },
      });
    };

    const goToOrder = (item) => {
      router.push({
        name: "OrderDetails",
        path: "/order/details/",
        params: { pk: item.pk },
      });
    };

    store.dispatch("getClient", { pk: route.params?.pk });
    getClientOrders();
    getClientMessages();
    getClientInvoices();

    watch(
      () => serverLogsOptions.value,
      function () {
        getClientOrders();
      }
    );

    watch(
      () => serverOptions1.value,
      function () {
        getClientMessages();
      }
    );

    watch(
      () => clientData.value,
      function (newValue) {
        clientObj.value = newValue.data;
      }
    );

    watch(
      () => clientOrdersData.value,
      function () {
        loading.value = false;
      }
    );

    return {
      errorsAdminData,
      showErrors,
      ordersHeaders,
      messagesHeaders,
      invoicesHeaders,
      clientOrdersData,
      clientMessagesData,
      clientInvoicesData,
      itemsAll,
      items,
      loading,
      serverOptions,
      serverOptions1,
      clientObj,
      searchValue,
      goToMessage,
      goToOrder,
    };
  },
  computed: {},
};
</script>
